import React from 'react';
import ReactPlayer from "react-player";

const Detail = (props: any) => {
  return (
    <div className="mainWrapper">
      <ReactPlayer
        url={`https://redirector.googlevideo.com/videoplayback?id=d2a01ea07b1a56ba&itag=22&source=youtube&requiressl=yes&ei=v7p2XuClBPq0mLAPqYuIkAg&susc=ytcp&mime=video/mp4&dur=8092.595&lmt=1583693446034035&txp=2216222&cmo=secure_transport=yes&ip=0.0.0.0&ipbits=0&expire=1584868159&sparams=ip,ipbits,expire,id,itag,source,requiressl,ei,susc,mime,dur,lmt&signature=D6092AD1BAE719A53362A7422F70D3C2B7522C97546EC6222A517A934A686DEA.8ED6D67E3CB6C40BB5AD282335560EE775308A34BF2EF0066EB3D141AB6FA487&key=us0`}
        playing
        loop
        controls
      />
    </div>
  );
};

export default Detail;
import { fork, take, call } from "redux-saga/effects";
import { ACTION } from "./const";

const getListApi = () => {
  return fetch(
    // "https://us-central1-house-management-4f0ba.cloudfunctions.net/helloWorld",
    'http://www.phimmoi.net',
  )
    .then(res => res.json())
    .then(result => console.log(result))
    .catch(err => Error(err));
};

function* getListSaga() {
  while (true) {
    yield take(ACTION.GET_LIST);
    const data = yield call(getListApi);
    console.log(data);
  }
}

export default function* root() {
  yield fork(getListSaga);
}

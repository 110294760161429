import React from "react";
import Home from "./module/home";
import Detail from './module/detail';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './styles/_home.scss';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/film/:filmName" component={Detail} />
        <Route path="/"  component={Home}/>
      </Switch>
    </Router>
  );
};

export default App;

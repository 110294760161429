import React from "react";
import { connect } from "react-redux";
import { getList } from "./action";
import { Link } from 'react-router-dom';
// import { Button } from "@material-ui/core";
// import { Notifications } from "@material-ui/icons";

const Home = (props: any) => {
  const { getList } = props;
  return (
    <div className="mainWrapper">
      <Link to="/film/little-woman">
        <img src='http://image.phimmoi.net/film/9652/poster.medium.jpg' alt="poster" />
      </Link>
      {/* <Button onClick={() => getList()} color="primary">
        <Notifications />
        <span>CLick Test</span>
      </Button> */}
    </div>
  );
};

export default connect(null, { getList })(Home);
